import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import ArticleCommentChoisirUneAgenceWeb from './pages/articles/ArticleCommentChoisirUneAgenceWeb';

const Home = loadable(() => import('./pages/Home'));
const Legals = loadable(() => import('./pages/Legals'));
const Articles = loadable(() => import('./pages/Articles'));

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/mentions-legales' element={<Legals />} />
          <Route path='/articles' element={<Articles />} />
          <Route path='/articles/comment-choisir-une-agence-web' element={<ArticleCommentChoisirUneAgenceWeb />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;