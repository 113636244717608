import React from 'react';

const Footer = () => {
    return (
        <>
            <footer>
                <p>Shield4Web conçu par Shield4Cloud SAS <br /> (894 529 452 00019 / <a href="/mentions-legales" target="_blank">MENTION-LEGALE</a>)</p>
            </footer>
        </>
    );
};

export default Footer;