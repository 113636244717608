import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadComponent } from "react-lazy-load-image-component";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fixed: props.fixed,
    };
  }

  render() {
    let navbar;

    if (this.state.fixed) {
      navbar = "navbar navbar-expand-lg navbar-dark bg-blue py-4";
    } else {
      navbar =
        "navbar navbar-expand-lg navbar-transparent navbar-dark bg-dark py-4";
    }

    return (
      <div>
        <LazyLoadComponent>
          <Helmet>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Nunito:400,600,700,800|Roboto:400,500,700&display=swap"
            />
          </Helmet>
        </LazyLoadComponent>
        <nav className={navbar}>
          <div className="container">
            <div className="navbar-brand">
              <strong>SHIELD</strong>4<strong>WEB</strong>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-action="offcanvas-open"
              data-target="#navbar_main"
              aria-controls="navbar_main"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="navbar-collapse offcanvas-collapse"
              id="navbar_main"
            >
              <ul className="navbar-nav ml-auto align-navbar-center">
                <h6 className="dropdown-header font-weight-600 d-lg-none px-0">
                  Menu
                </h6>
                <li className="nav-item">
                  <a className="nav-link" href="/#accueil">
                    ACCUEIL
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#atouts">
                    NOS ATOUTS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#tarifs">
                    TARIFS
                  </a>
                </li>
                {/*<li className="nav-item">
                                    <a className="nav-link" href="/articles">
                                        ARTICLES
                                    </a>
                                </li>*/}
                <li className="nav-item">
                  <a className="nav-link" href="/#avis">
                    AVIS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#skill">
                    EXPÉRIENCE
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#entreprise">
                    ENTREPRISE
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav ml-auto align-items-lg-center">
                <li className="nav-item">
                  <button
                    onClick={() => window.called()}
                    className="called button-wiggle btn btn-sm bg-white d-none d-lg-inline-flex"
                  >
                    NOUS-APPELEZ
                    <br />
                    24/24H
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navigation;
